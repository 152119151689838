<template>
    <div>
        <v-container
            id="users"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="auto" cols="12">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Learn</v-toolbar-title
                        >
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort By"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 11.25rem"
                            clearable
                            @click:clear="content.options.uploadSort = null"
                            v-model="content.options.uploadSort"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 11.25rem"
                            clearable
                            @click:clear="content.options.perPage = 10"
                            v-model="content.options.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="auto" cols="12">
                        <v-btn
                            class="border-radius-25 px-5 mx-1"
                            @click="openMaterialFormDialog('add')"
                            depressed
                            rounded
                            dark
                            color="accent"
                            >Add Material</v-btn
                        >
                    </v-col>
                    <v-col md="auto" cols="12" class="d-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by collection"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="learnCollections"
                            item-value="name"
                            item-text="name"
                            small-chips
                            style="max-width: 20rem"
                            v-model="content.options.learnCollection"
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip
                                    class="white--text"
                                    color="secondary"
                                    small
                                    :key="JSON.stringify(item)"
                                    >{{ item.name }}</v-chip
                                >
                            </template>
                        </v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by tag"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="learnTags"
                            small-chips
                            multiple
                            style="max-width: 20rem"
                            v-model="content.options.learnTags"
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip
                                    class="white--text"
                                    color="secondary"
                                    small
                                    :key="JSON.stringify(item)"
                                    >{{ item }}</v-chip
                                >
                            </template>
                        </v-select>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="max-width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        lg="4"
                        xl="3"
                        v-for="(material, index) in content.items"
                        :key="index"
                    >
                        <learn-material-preview-card
                            :materialID="material.id"
                            :title="material.title"
                            :thumbnail="material.thumbnail"
                            :learn-collection="material.learn_collection"
                            :subjects="material.life_journey_subjects"
                            @onViewInformation="
                                openMaterialInformationDialog(material)
                            "
                            @onDeleteSuccess="resetContent"
                            :key="index"
                        ></learn-material-preview-card>
                    </v-col>
                </v-row>

                <learn-material-loading-skeleton-loaders
                    v-if="isGetMaterialsStart"
                ></learn-material-loading-skeleton-loaders>
            </v-container>

            <learn-material-form-dialog
                :show.sync="materialForm.show"
                :action="materialForm.action"
                :data="materialForm.data"
                @onSuccess="resetContent"
                @close="
                    materialForm = { ...materialForm, show: false, data: null }
                "
            ></learn-material-form-dialog>

            <learn-material-information-dialog
                :show.sync="materialInformationDialog.show"
                :data="materialInformationDialog.data"
                @close="
                    materialInformationDialog = {
                        ...materialInformationDialog,
                        show: false,
                        data: null,
                    }
                "
                @onOpenUpdateForm="openMaterialFormDialog('edit')"
            ></learn-material-information-dialog>
        </v-container>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="content.options.dateFrom"
            :end-date.sync="content.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>

        <base-infinite-scroll
            :action="getMaterials"
            :identifier="infiniteId"
        ></base-infinite-scroll>
    </div>
</template>

<script>
import {
    GET_LEARN_COLLECTIONS,
    GET_LEARN_TAGS,
    GET_VIDEOS,
} from "@/store/modules/selection";
import LearnMaterialFormDialog from "@/components/MainView/Learn/components/MaterialFormDialog";
import LearnMaterialLoadingSkeletonLoaders from "@/components/MainView/Learn/components/MaterialLoadingSkeletonLoaders";
import BaseInfiniteScroll from "@/components/Base/BaseInfiniteScroll";
import { LEARN_GET_MATERIALS } from "@/store/modules/learn";
import LearnMaterialPreviewCard from "@/components/MainView/Learn/components/MaterialPreviewCard";
import LearnMaterialInformationDialog from "@/components/MainView/Learn/components/MaterialInformationDialog";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";

export default {
    name: "learn",

    components: {
        LearnMaterialInformationDialog,
        LearnMaterialPreviewCard,
        BaseInfiniteScroll,
        LearnMaterialLoadingSkeletonLoaders,
        LearnMaterialFormDialog,
        BaseDateRangeDialog,
    },

    data() {
        return {
            infiniteId: +new Date(),
            content: {
                items: [],
                options: {
                    uploadSort: "desc",
                    learnCollection: [],
                    learnTags: [],
                    dateFrom: null,
                    dateTo: null,
                },
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            materialForm: { show: false, action: "", data: {} },
            materialInformationDialog: { show: false, data: null },
            isGetMaterialsStart: false,
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        learnCollections() {
            return this.$store.state.selection.learnCollections;
        },

        learnTags() {
            return this.$store.state.selection.learnTags;
        },

        videos() {
            return this.$store.state.selection.videos;
        },
    },

    watch: {
        ["content.options"]: {
            handler() {
                this.content.pagination.page = 1;
                this.resetContent();
            },
            deep: true,
        },
    },

    methods: {
        async initiate() {
            await this.$store.dispatch(GET_LEARN_COLLECTIONS);
            await this.$store.dispatch(GET_LEARN_TAGS);
            await this.$store.dispatch(GET_VIDEOS);
        },

        openMaterialInformationDialog(data) {
            this.materialInformationDialog = {
                show: true,
                data: Object.assign({}, data),
            };
        },

        resetContent() {
            this.content.items = [];
            this.infiniteId += 1;
        },

        openMaterialFormDialog(action) {
            switch (action) {
                case "add":
                    this.materialForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.materialForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.materialInformationDialog.data
                        ),
                    };
                    break;
                case "delete":
                    break;
                default:
                    this.materialForm = { show: true, action, data: null };
                    break;
            }
        },

        async getMaterials($state) {
            const { uploadSort, learnCollection, learnTags, dateFrom, dateTo } =
                this.content.options;

            const { page, perPage } = this.content.pagination;
            const payload = {
                page: page || 1,
                perPage: perPage || 10,
                uploadSort: uploadSort || "desc",
                learnCollection:
                    typeof learnCollection === "string"
                        ? [learnCollection]
                        : learnCollection || [],
                tags: learnTags || [],
                dateFrom: dateFrom || null,
                dateTo: dateTo || null,
            };
            this.isGetMaterialsStart = true;
            const { success, data } = await this.$store.dispatch(
                LEARN_GET_MATERIALS,
                payload
            );
            if (success) {
                const items = data.data;
                if (items.length === this.content.pagination.perPage) {
                    this.content.items = [...this.content.items, ...items];
                    this.content.pagination.page += 1;
                    this.isGetMaterialsStart = false;
                    $state.loaded();
                    return;
                }
                this.content.items = [...this.content.items, ...items];
                this.isGetMaterialsStart = false;
                $state.complete();
            }
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
</style>
