<template>
    <base-dialog width="1069" :is-open.sync="dialog">
        <v-card-title
            class="align-center justify-start"
            style="background-color: #f1eeee"
        >
            <v-btn
                class="border-radius-25 px-5 text-capitalize"
                rounded
                dark
                color="success"
                depressed
                @click="openUpdateVideoForm"
                >Update</v-btn
            >
        </v-card-title>

        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="px-5">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" v-if="thumbnail">
                        <v-img
                            class="rounded-lg"
                            width="100%"
                            max-height="548px"
                            :src="thumbnail"
                        >
                        </v-img>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <div
                                    class="d-flex align-center align-content-center"
                                >
                                    <v-list-item three-line style="width: 100%">
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-2">
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <span
                                                            class="font-weight-bold success--text"
                                                            style="
                                                                word-wrap: break-word;
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >{{ title }}</span
                                                        >
                                                    </template>
                                                    {{ title }}
                                                </v-tooltip>
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                class="secondary--text text-uppercase mb-2"
                                                v-if="learnCollection"
                                                >{{
                                                    learnCollection.name
                                                }}</v-list-item-subtitle
                                            >
                                            <v-list-item-subtitle
                                                v-if="firstSubject"
                                            >
                                                <v-chip
                                                    class="font-weight-bold secondary mr-2 text-capitalize"
                                                    small
                                                    dark
                                                >
                                                    {{ firstSubject }}
                                                </v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </v-col>

                            <v-col cols="12" md="6">
                                <div
                                    :class="[
                                        { 'left-border': !!description },
                                        'd-flex fill-height align-center px-5  black--text',
                                    ]"
                                >
                                    {{ description }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
export default {
    name: "learn-material-information-dialog",

    components: { BaseDialog },

    props: {
        show: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            dataLocal: Object.assign({}, this.data),
            error: null,
        };
    },

    computed: {
        title() {
            if (!this.data) return null;
            return this.data.title;
        },

        description() {
            if (!this.data) return null;
            return this.data.description;
        },

        thumbnail() {
            if (!this.data) return null;
            return this.data.thumbnail;
        },

        learnCollection() {
            if (!this.data) return null;
            return this.data.learn_collection;
        },

        firstSubject() {
            if (!this.data) return null;
            const { life_journey_subjects, learn_collection } = this.data;
            let subject = null;
            if (!life_journey_subjects || learn_collection.id === 2)
                return subject;
            if (life_journey_subjects.length === 0) return subject;
            const { title } = life_journey_subjects[0];
            subject = `Life Journey 1: ${title}`;

            return subject;
        },
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(to) {
            if (!to) {
                this.$emit("close");
            }
        },

        data(value) {
            if (value) {
                this.dataLocal = Object.assign({}, value);
            }
        },
    },

    methods: {
        openUpdateVideoForm() {
            this.dialog = false;
            this.$emit("onOpenUpdateForm");
        },
    },
};
</script>

<style lang="scss" scoped>
.left-border {
    border-left: 2px solid rgba(#78756a, 20%);
}
</style>
